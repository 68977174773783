"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { searchProduct } from "@services/search";
import debounce from "@helpers/debounce";
const initSearchbar = () => {
  const { Theme } = window;
  const { searchBar: SearchBarMap } = Theme.selectors;
  const searchCanvas = document.querySelector(SearchBarMap.searchCanvas);
  const searchWidget = document.querySelector(SearchBarMap.searchWidget);
  const searchDropdown = document.querySelector(SearchBarMap.searchDropdown);
  const searchResults = document.querySelector(SearchBarMap.searchResults);
  const searchTemplate = document.querySelector(SearchBarMap.searchTemplate);
  const searchInput = document.querySelector(SearchBarMap.searchInput);
  const searchUrl = searchWidget == null ? void 0 : searchWidget.dataset.searchControllerUrl;
  searchCanvas == null ? void 0 : searchCanvas.addEventListener("hidden.bs.offcanvas", () => {
    if (searchDropdown) {
      searchDropdown.innerHTML = "";
      searchDropdown.classList.add("d-none");
    }
  });
  if (searchWidget && searchInput && searchResults && searchDropdown) {
    searchInput.addEventListener("keydown", debounce(() => __async(void 0, null, function* () {
      if (searchUrl) {
        const products = yield searchProduct(searchUrl, searchInput.value, 10);
        if (products.length > 0) {
          searchResults.innerHTML = "";
          products.forEach((e) => {
            const product = searchTemplate == null ? void 0 : searchTemplate.content.cloneNode(true);
            if (product) {
              const productLink = product.querySelector("a");
              const productTitle = product.querySelector("p");
              const productImage = product.querySelector("img");
              if (productLink && productTitle && productImage) {
                productLink.href = e.canonical_url;
                productTitle.innerHTML = e.name;
                if (!e.cover) {
                  productImage.innerHTML = "";
                } else {
                  productImage.src = e.cover.small.url;
                }
                searchResults.append(product);
              }
            }
          });
          searchDropdown.classList.remove("d-none");
          window.addEventListener("click", (e) => {
            if (!searchWidget.contains(e.target)) {
              searchDropdown.classList.add("d-none");
            }
          });
        } else {
          searchResults.innerHTML = "";
          searchDropdown.classList.add("d-none");
        }
      }
    }), 250));
  }
};
export default initSearchbar;
