"use strict";
const initVisiblePassword = () => {
  const { Theme } = window;
  const { visiblePassword: visiblePasswordMap } = Theme.selectors;
  const visiblePasswordList = document.querySelectorAll(visiblePasswordMap.visiblePassword);
  if (visiblePasswordList.length > 0) {
    visiblePasswordList.forEach((visiblePasswordInput) => {
      const visiblePasswordBtn = visiblePasswordInput == null ? void 0 : visiblePasswordInput.nextElementSibling;
      visiblePasswordBtn == null ? void 0 : visiblePasswordBtn.addEventListener("click", () => {
        const visiblePasswordIcon = visiblePasswordBtn.firstElementChild;
        let type = visiblePasswordInput.getAttribute("type");
        let typeIcon = "visibility";
        if (type === "password") {
          type = "text";
          typeIcon = "visibility_off";
        } else {
          type = "password";
        }
        visiblePasswordInput.setAttribute("type", type);
        if (visiblePasswordIcon) {
          visiblePasswordIcon.innerHTML = typeIcon;
        }
      });
    });
  }
};
export default initVisiblePassword;
