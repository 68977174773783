"use strict";
import { isHTMLElement } from "@helpers/typeguards";
const initMobileMenu = () => {
  const { Theme } = window;
  const { mobileMenu: MobileMenuMap } = Theme.selectors;
  const openChildsButtons = document.querySelectorAll(MobileMenuMap.openChildsButton);
  const backTitle = document.querySelector(MobileMenuMap.backTitle);
  const backButton = document.querySelector(MobileMenuMap.backButton);
  const menuCanvas = document.querySelector(MobileMenuMap.menuCanvas);
  const defaultBackTitle = backTitle == null ? void 0 : backTitle.innerHTML;
  const backToParent = () => {
    var _a;
    if (backTitle && backButton && defaultBackTitle) {
      const currentMenu = document.querySelector(MobileMenuMap.menuCurrent);
      const currentDepth = Number(currentMenu == null ? void 0 : currentMenu.dataset.depth);
      const currentParentDepth = currentDepth === 2 ? 0 : currentDepth - 1;
      const currentParent = document.querySelector(MobileMenuMap.specificParent(currentParentDepth));
      if (currentDepth === 2) {
        backButton.classList.add("d-none");
      }
      if (currentMenu) {
        currentMenu.classList.remove("js-menu-current", "menu--current");
      }
      if (currentParent) {
        if (currentDepth > 3) {
          backTitle.innerHTML = (_a = currentParent.dataset.backTitle) != null ? _a : "";
        } else {
          backTitle.innerHTML = defaultBackTitle;
        }
        currentParent.classList.add("js-menu-current", "menu--fromLeft", "menu--current");
        currentParent.classList.remove("menu--parent");
      }
    }
  };
  menuCanvas == null ? void 0 : menuCanvas.addEventListener("hidden.bs.offcanvas", () => {
    const currentMenu = document.querySelector(MobileMenuMap.menuCurrent);
    if (isHTMLElement(currentMenu)) {
      let currentDepth = Number(currentMenu.dataset.depth);
      if (currentDepth !== 0) {
        while (currentDepth >= 2) {
          backToParent();
          currentDepth -= 1;
        }
      }
    }
  });
  openChildsButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const currentMenu = document.querySelector(MobileMenuMap.menuCurrent);
      if (currentMenu) {
        const currentDepth = Number(currentMenu.dataset.depth);
        const currentButton = button;
        if (currentMenu) {
          currentMenu.classList.remove("js-menu-current", "menu--current", "menu--fromLeft", "menu--fromRight");
          currentMenu.classList.add("menu--parent");
        }
        const child = document.querySelector(MobileMenuMap.specificChild(currentButton.dataset.target));
        backButton == null ? void 0 : backButton.classList.remove("d-none");
        if (currentDepth >= 1 && backTitle && (child == null ? void 0 : child.dataset.backTitle)) {
          backTitle.innerHTML = child.dataset.backTitle;
        }
        if (isHTMLElement(child)) {
          child.classList.add("js-menu-current", "menu--fromRight", "menu--current");
          child.classList.remove("js-menu-child", "menu--child");
        }
      }
    });
  });
  backButton == null ? void 0 : backButton.addEventListener("click", () => {
    backToParent();
  });
};
export default initMobileMenu;
