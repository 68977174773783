"use strict";
import noUiSlider from "nouislider";
import wNumb from "wnumb";
import initFacets from "./update";
import filterHandler from "./filter-handler";
const initSliders = () => {
  const { Theme } = window;
  document.querySelectorAll(Theme.selectors.facetedsearch.filterSlider).forEach((filter) => {
    const container = filter.querySelector(Theme.selectors.facetedsearch.rangeContainer);
    const options = JSON.parse(container.dataset.sliderSpecifications);
    const signPosition = options.positivePattern.indexOf("\xA4") === 0 ? "prefix" : "suffix";
    const sliderDirection = container.dataset.sliderDirection === "1" ? "rtl" : "ltr";
    const min = parseInt(container.dataset.sliderMin, 10);
    const max = parseInt(container.dataset.sliderMax, 10);
    let initiatedSlider;
    const tooltipsFormat = wNumb({
      decimals: 2,
      [signPosition]: signPosition === "prefix" ? options.currencySymbol : ` ${options.currencySymbol}`
    });
    const sliderValues = JSON.parse(container.dataset.sliderValues);
    if (!container.noUiSlider) {
      initiatedSlider = noUiSlider.create(container, {
        start: sliderValues != null ? sliderValues : [min, max],
        tooltips: [tooltipsFormat, tooltipsFormat],
        direction: sliderDirection,
        connect: [false, true, false],
        range: {
          min,
          max
        }
      });
      initiatedSlider.on("set", (values, handle, unencoded, tap, positions, instance) => {
        filterHandler(values, instance);
      });
    } else {
      container.noUiSlider.updateOptions({
        start: sliderValues != null ? sliderValues : [min, max],
        tooltips: [tooltipsFormat, tooltipsFormat],
        range: {
          min,
          max
        }
      }, true);
      container.noUiSlider.on("set", (values, handle, unencoded, tap, positions, instance) => {
        filterHandler(values, instance);
      });
    }
  });
};
const toggleLoader = (toggle) => {
  const { Theme } = window;
  const loader = document.querySelector(Theme.selectors.pageLoader);
  if (loader) {
    loader.classList.toggle("d-none", toggle);
  }
};
document.addEventListener("DOMContentLoaded", () => {
  const { prestashop, Theme: { events } } = window;
  initFacets();
  prestashop.on(events.updateProductList, () => {
    toggleLoader(true);
    initSliders();
  });
  initSliders();
  prestashop.on(events.updateFacets, () => {
    toggleLoader(false);
  });
});
