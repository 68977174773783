"use strict";
const initFormValidation = (selector) => {
  const { Theme } = window;
  const { formValidation: formValidationMap } = Theme.selectors;
  const formValidationList = document.querySelectorAll(selector != null ? selector : formValidationMap.default);
  formValidationList.forEach((formElement) => {
    formElement.addEventListener("submit", (event) => {
      if (!formElement.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      formElement.classList.add("was-validated");
    }, false);
  });
};
export default initFormValidation;
