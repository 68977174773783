"use strict";
import SelectorsMap from "@constants/selectors-map";
const initCustomer = () => {
  const returnTableMainCheckbox = document.querySelector(SelectorsMap.order.returnFormMainCheckbox);
  const returnTableItemCheckbox = document.querySelectorAll(SelectorsMap.order.returnFormItemCheckbox);
  if (returnTableMainCheckbox) {
    returnTableMainCheckbox == null ? void 0 : returnTableMainCheckbox.addEventListener("click", () => {
      const checked = returnTableMainCheckbox == null ? void 0 : returnTableMainCheckbox.checked;
      returnTableItemCheckbox.forEach((checkbox) => {
        if (checked === true) {
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
        }
      });
    });
  }
};
export default initCustomer;
