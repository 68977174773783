"use strict";
import SelectorsMap from "./constants/selectors-map";
export default () => {
  const { prestashop, Theme: { events } } = window;
  const initProductSlide = () => {
    const imagesCarousel = document.querySelector(SelectorsMap.product.carousel);
    if (imagesCarousel) {
      imagesCarousel.addEventListener("slide.bs.carousel", onProductSlide);
    }
  };
  function onProductSlide(event) {
    const thumbnails = document.querySelectorAll(SelectorsMap.product.thumbnail);
    thumbnails.forEach((e) => {
      e.classList.remove("active");
    });
    const activeThumbnail = document.querySelector(SelectorsMap.product.activeThumbail(event.to));
    if (activeThumbnail) {
      activeThumbnail.classList.add("active");
    }
  }
  initProductSlide();
  prestashop.on(events.updatedProduct, () => {
    initProductSlide();
  });
  prestashop.on(events.quickviewOpened, () => {
    initProductSlide();
  });
  $(document).on("click", "#category .navbar-toggler .add", function() {
    $(".collapse.show").removeClass("show");
    $(".remove").addClass("d-none");
    $(".add").addClass("d-inline-block").removeClass("d-none");
    var parent = $(this).parent().parent().parent().parent();
    parent.find(".collapse").toggleClass("show");
    parent.find(".remove").toggleClass("d-none");
    $(this).addClass("d-none").removeClass("d-inline-block");
  });
  $(document).on("click", "#category .navbar-toggler .remove", function() {
    $(".add").addClass("d-inline-block");
    var parent = $(this).parent().parent().parent().parent();
    parent.find(".collapse").toggleClass("show");
    parent.find(".add").removeClass("d-none").addClass("d-inline-block");
    $(this).toggleClass("d-none");
  });
  $(document).on("click", ".toggle_facets", function() {
    $(".other_facets").toggleClass("d-none").toggleClass("d-flex");
  });
  $(".filter_btn_mobile").on("click", function() {
    $(".facets_mobile").removeClass("d-none");
  });
  $(".search-link").on("click", function() {
    $(this).children().children().prop("checked", function(i, value) {
      return !value;
    });
  });
  $(".valid_filters").on("click", function() {
    $(".facets_mobile").addClass("d-none");
    location.reload();
  });
  $(".close_filters_mobile").on("click", function() {
    $(".facets_mobile").addClass("d-none");
  });
  $("#search_widget .search").on("click", function() {
    $(".js-search-input").toggleClass("d-none");
    $(".search-widgets").toggleClass("open");
  });
  $("#search_widget .clear").on("click", function() {
    $(".js-search-input").toggleClass("d-none");
    $(".search-widgets").toggleClass("open");
  });
  $(".product_reviews").append($("#ts_product_widget"));
  $(".nav .nav-link").on("click", function() {
    $(".nav").find(".active").removeClass("active");
    $(this).addClass("active");
  });
};
